<div class="container-fluid mt-4">
    <div class="container mt-4" height="30px">
        <div class="row"><br><br><br></div>
    </div>
    <div class="row p-2" style="    margin-top: 50px;
    margin-bottom: 50px;">
        <div class="col-md-4 shape-contact">
            <br>
            <h2 class="mt-3">Contactanos</h2>
            <br>

            <p>Si tienes alguna duda o comentario, llena el siguiente formulario o bien. También, puedes seguirnos en nuestras redes sociales
            </p>
            <br>
            <p>
                <mat-icon class="position-icon"> location_on</mat-icon>
                Ubicación : Guanajuato, México
            </p>
            <br>
            <p>
                <mat-icon class="position-icon"> phonelink_ring</mat-icon>
                Telefono : +52 461 239 6508
            </p>
            <br>
            <p>
                <mat-icon class="position-icon"> mail</mat-icon>
                Correo : ventas@solucionestogas.com
            </p>
            <br>
            <div class="social-btns text-center">
                <a class="btn facebook" target="_blank" href="https://www.facebook.com/TOGAS-105389408558562">
                    <mat-icon> facebook</mat-icon>
                    Facebook
                </a>
            </div>
        </div>
        <div class="col-md-8">
            <form (submit)="send()">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Nombre:</mat-label>
                                <input matInput class="mdc-text-field__input" aria-describedby="filled-full-width-helper-text" id="filled-full-width" placeholder="Nombre Usuario" [formControl]="nombre" required pattern="[a-zA-Z ]*">
                                <mat-error *ngIf="nombre.invalid">{{ErrorMessageName()}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Apellido:</mat-label>
                                <input matInput class="mdc-text-field__input" aria-describedby="filled-full-width-helper-text" id="filled-full-width" placeholder="Apellido Usuario" [formControl]="apellido" required pattern="[a-zA-Z ]*">
                                <mat-error *ngIf="apellido.invalid">{{ErrorMessageName()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Ingresa tu correo</mat-label>
                                <input matInput class="mdc-text-field__input" aria-describedby="filled-full-width-helper-text" id="filled-full-width" placeholder="usuario@email.com" [formControl]="email" required>
                                <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Telefono:</mat-label>
                                <input matInput class="mdc-text-field__input" aria-describedby="filled-full-width-helper-text" id="filled-full-width" placeholder="4611224223" [formControl]="telefono" maxlength='10' paterrn="/^[0-9]$/" required>
                                <mat-error *ngIf="telefono.invalid">{{ErrorMessagePhone()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Asunto</mat-label>
                                <input matInput class="mdc-text-field__input" aria-describedby="filled-full-width-helper-text" id="filled-full-width" placeholder="Cotizacion | Duda " [formControl]="asunto" required>
                                <mat-error *ngIf="asunto.invalid">{{ErrorMessageAsunto()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="fill">
                            <mat-label>Mensaje</mat-label>
                            <textarea matInput rows="6" cols="100" placeholder="mensaje" [formControl]="messagge" required></textarea>
                            <mat-error *ngIf="messagge.invalid">{{ErrorMessageMenssage()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-md-9">
                    </div>
                    <div class="col-md-3">
                        <div class="round">
                            <button type="submit" id="submitBtn" style="display:none;">Hidden Button</button>
                            <a href="javascript:;" class="button" onclick="$('#submitBtn').click();">Enviar mensaje</a>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>