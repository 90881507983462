<mdb-navbar (window:scroll)="isHomeRouteActivated()" *ngIf="isHomeRouteActivated()" SideClass="navbar navbar-expand-lg navbar-dark transparent fixed-top-i expanda">

    <!--    <mdb-navbar-brand>
        <a class="navbar-brand" href="#">Méllissa diawakana</a>
    </mdb-navbar-brand>
-->
    <!-- Collapsible content -->
    <links>
        <!-- Links -->
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link waves-effect waves-light" href="" [routerLink]="['inicio']">
                    <i class="fas fa-home"></i> Inicio
                    <span class="sr-only">(current)</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link waves-effect waves-light" href="acercade" [routerLink]="['acercade']">
                   Acerca de nosotros
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link waves-effect waves-light" href="services" [routerLink]="['services']">
                    Servicios y productos
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link waves-effect waves-light" href="galeria" [routerLink]="['galeria']">
                    Galería</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link waves-effect waves-light" href="contacto" [routerLink]="['contacto']">
                Contactanos</a>
            </li>
        </ul>
        <!-- Links -->
    </links>
    <!-- Collapsible content -->

</mdb-navbar>

<mdb-navbar (window:scroll)="!isHomeRouteActivated()" *ngIf="!isHomeRouteActivated()" id="target" SideClass="navbar navbar-expand-lg navbar-light transparent-scroll fixed-top-d expanda-movil">

    <!-- Navbar brand -->
    <mdb-navbar-brand>
        <img src="../../../assets/img/togas.png" style="width: 100px;">
    </mdb-navbar-brand>

    <!-- Collapsible content -->
    <links>
        <!-- Links -->
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" routerLinkActive="active">
                <a class="text-navbar nav-link waves-effect waves-light" style="color: #0196d0 !important;
                " href="" [routerLink]="['inicio']">
                    <i class="fas fa-home text-navbar"></i> Inicio
                    <span class="sr-only">(current)</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="subtext-navbar nav-link waves-effect waves-light" style="color: #0196d0 !important;
                " href="" [routerLink]="['acercade']">
                    Acerca de nosotros
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="subtext-navbar nav-link waves-effect waves-light" style="color: #0196d0 !important;
                " href="" [routerLink]="['services']">
                    Servicios y productos
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="subtext-navbar nav-link waves-effect waves-light" style="color: #0196d0 !important;
                " href="galeria" [routerLink]="['galeria']">
                    Galería</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="subtext-navbar nav-link waves-effect waves-light" style="color: #0196d0 !important;
                " href="contacto" [routerLink]="['contacto']">
                Contactanos</a>
            </li>
        </ul>
        <!-- Links -->
    </links>
    <!-- Collapsible content -->

</mdb-navbar>