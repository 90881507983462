import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './src/index/index.component';
import { NavigationbarComponent } from './src/navigationbar/navigationbar.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { APP_ROUTING } from './app.routes';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FooterComponent } from './src/footer/footer.component';
import { UsComponent } from './src/us/us.component';
import { GalleryComponent } from './src/gallery/gallery.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { ContactComponent } from './src/contact/contact.component';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServicesComponent } from './components/services/services.component'; 

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    NavigationbarComponent,
    FooterComponent,
    UsComponent,
    GalleryComponent,
    ContactComponent,
    ServicesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    NgxGalleryModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    APP_ROUTING,
    MDBBootstrapModule.forRoot(),

  ],
  exports: [ MatFormFieldModule, MatInputModule ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
