<section class="">
    <!-- Footer -->
    <footer style="background-color: #00a2e2 !important;" class="bg-secondary text-white">
        <!-- Grid container -->
        <div class="footer-container">
            <!--Grid row-->
            <div class="row m0-foo">
                <!--Grid column-->
                <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
                    <img style="width: 175px;margin-bottom: 35px;" src="../../../assets/img/togas6.png">
                    <div class="row m0-foo">
                        <div class="col-md-12">
                            <div>
                                <owl-carousel-o [options]="customOptions ">
                                    <ng-template carouselSlide>
                                        <img class="img-carousel " src="../../../assets/img/marcas/fepetro3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/cim-tek3.png "></ng-template>
                                    <ng-template carouselSlide><img style="margin-top: 14px; " class="img-carousel " src="../../../assets/img/marcas/opw3.png "></ng-template>
                                    <ng-template carouselSlide><img style="margin-top: 14px; " class="img-carousel " src="../../../assets/img/marcas/gilbarco3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/wayne3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/conitech3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/adt3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/schweerd3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/emco3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/husky3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/fiber3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/civacon3.png "></ng-template>
                                    <ng-template carouselSlide><img style="margin-top: 13px; " class="img-carousel " src="../../../assets/img/marcas/petroclear3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/liquid3.png "></ng-template>
                                </owl-carousel-o>
                            </div>
                        </div>

                    </div>

                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-3 col-md-6 mb-4 mb-md-0 ">
                    <h5 style=" font-size: 16px; font-weight: 400; " class="text-uppercase ">Servicios</h5>

                    <ul class="list-unstyled mb-0 ">
                        <li>
                            <p style="margin-bottom: 0px; ">Construcciones pluviales y sanitarias</p>
                        </li>
                        <li>
                            <p style="margin-bottom: 0px; ">Construcción estructural</p>
                        </li>
                        <li>
                            <p style="margin-bottom: 0px; ">Alumbrados perimetrales</p>
                        </li>
                        <li>
                            <p style="margin-bottom: 0px; ">Construcciones electricas</p>
                        </li>
                        <li>
                            <p style="margin-bottom: 0px; ">Renta de maquinaria pesada</p>
                        </li>
                        <li>
                            <p style="margin-bottom: 0px; ">Renta de grua y maniobra HIAB</p>
                        </li>
                        <li>
                            <p style="margin-bottom: 0px; ">Herreria y paileria</p>
                        </li>
                    </ul>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-3 col-md-6 mb-4 mb-md-0 ">
                    <h5 style=" font-size: 16px; font-weight: 400; " class="text-uppercase ">Contactanos</h5>

                    <ul class="list-unstyled mb-0 ">
                        <li>
                            <p style="margin-bottom: 0px; ">ventas@solucionestogas.com</p>
                        </li>
                        <li>
                            <p style="margin-bottom: 0px; ">administracion@solucionestogas.com</p>
                        </li>
                        <li>
                            <p style="margin-bottom: 0px; ">contacto@solucionestogas.com</p>
                        </li>
                        <li>
                            <p style="margin-bottom: 0px; ">facturas@solucionestogas.com</p>
                        </li>
                        <li>
                            <p style="margin-bottom: 0px; ">proyectos@solucionestogas.com</p>
                        </li>

                    </ul>

                </div>
            </div>
            <!--Grid row-->
        </div>
        <div class="row m0-foo">
            <div class="col-md-12">
                <hr class="line-white">
            </div>
            <div class="col-md-3"></div>
            <div class="col-md-2 text-center">
                <a href=" https://www.facebook.com/Togas-Celaya-106964295177320 " target="_blank " class="fa fa-facebook "></a>
                <p class="fa-text">Togas Celaya</p>
            </div>
            <div class="col-md-2 text-center ">
                <img style="width: 40px;height: 40px; " src="../../../assets/img/togas/whatsapp.png ">
                <p class="fa-text">461-132-4150 </p>
            </div>
            <div class=" col-md-2 text-center ">
                <img style="width: 35px;height: 40px; " src="../../../assets/img/togas/location.png ">
                <p class="fa-text">Celaya, Guanajuato México</p>
            </div>
            <div class="col-md-3"></div>
        </div>



        <!-- Grid container -->

        <!-- Copyright -->
        <div class="text-center p-3 " style="background-color: rgba(0, 0, 0, 0.2); ">
            ©2021 Desarrollado por:
            <a class="text-white " target="_blank " href="https://www.amber-dev.com/ "><img style="width: 100px; " src="../../../assets/img/amber.png "></a>
        </div>
        <!-- Copyright -->
    </footer>
    <!-- Footer -->
</section>