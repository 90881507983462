import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TweenMax, TweenLite} from "gsap";
import { OwlOptions } from 'ngx-owl-carousel-o';


declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  constructor(
    public router:Router
) { }

  ngOnInit() {
    $(".hover").mouseleave(
      function () {
        $(this).removeClass("hover");
      }
    );
    window.scrollTo(0, 0);
    $(function( $ ){
  
      var $body=$("body"),
    $heroA=$("#hero-section-a img"),
    $heroB=$("#hero-section-b img"),
    $heroC=$("#hero-section-c img");
    
    TweenMax.set( $heroA, { transformStyle: 'preserve-3d'  });
    TweenMax.set( $heroB, { transformStyle: 'preserve-3d'  });
    TweenMax.set( $heroC, { transformStyle: 'preserve-3d'  });
    
    $body.mousemove(function(e) {
      
      var sxPos =  e.pageX / $body.width()  * 30 - 10;
      var syPos =  e.pageY / $body.height() * 70 - 50;
      TweenMax.to( $heroA, 1, { rotationY: 0.05 * sxPos, rotationX: 0.20 * syPos, rotationZ: '-0.1', transformPerspective:500, transformOrigin:'center center' });
      TweenMax.to( $heroB, 1, { rotationY: 0.10 * sxPos, rotationX: 0.15 * syPos, rotationZ: 0, transformPerspective:500, transformOrigin:'center center' });
      TweenMax.to( $heroC, 1, { rotationY: 0.15 * sxPos, rotationX: 0.10 * syPos, rotationZ: 0.10, transformPerspective:500, transformOrigin:'center center' });
  
    });
  
  
  });
  $(document).ready(function(){
    var
      images = "#gallery > img" // image selector
    , interval = 3000           // milliseconds between transitions
    , index = 0                 // starting index
    , count = $(images).length  // image count
      // the transition loop
    , handle = setInterval(function() {
        // fade out the current image
        $(images + ":eq(" + index + ")").fadeOut('slow');
        // get the next index, or cycle back to 0
        if (++index === count) index = 0;
        // fade in the next image
        $(images + ":eq(" + index + ")").fadeIn('slow');
      }
      , interval
    )
    , stop = function(){
        clearInterval(handle);
    };
  });
  }


  comment(){
    this.router.navigate(['felicitaciones']);
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoplay:true,
    autoplayTimeout:1700,
    navSpeed: 200,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    },
    nav: true
  }

  
  openabout(){
    this.router.navigate(['acercade']);
  }

  openservices(){
    this.router.navigate(['services']);
  }
}



