<div class="slider">
    <div class="container container-b">
        <div class="row" style="width: 100%;">
            <div class="col-md-12 col-xs-12">
                <p class="description title-principal text-center"> Somos una <strong class="strong-sub"> empresa 100% Mexicana</strong></p>
            </div>
        </div>
    </div>
    <div></div>
    <div class="shape"><img src="../../../assets/img/basic-triangle-bottom.svg" alt="shape"></div>
</div>

<div class="container" style="margin-bottom: 70px;margin-top: 10px;">
    <div>
        <div class="row">
            <div class="col-md-6">
                <h3 class="title-why">¿Porque nosotros?</h3>
            </div>
            <div class="col-md-6 dir-btn">
                <button class="btn-togas"><span class="mat-button-wrapper">Conocer Más</span><div class="mat-ripple mat-button-ripple"></div><div class="mat-button-focus-overlay"></div></button>

            </div>
        </div>
    </div>

    <hr class="line-blue">
    <h5 class="subtitle-why">Somos especialistas en el cumplimiento de la operación y mantenimiento de tu estación de servicio, con más de 100 obras exitosas y 10 años de experiencia, brindándoles a nuestros clientes los mejores servicios además que contamos con personal altamente
        capacitado para lograr un mejor trabajo.</h5>
    <div class="row">
        <div class="col-md-4">
            <div class="card">
                <div class="image-div" style="background-image: url('../../../assets/img/togas/cuidamos.png');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card">Cuidamos de ti</h5>
                    <p class="card-text body-card">En TOGAS garantizamos calidad superior en todos nuestros trabajos y servicios por mas sencillos o complejos que estos puedan ser, cuidamos de tu empresa y economía como si fuera nuestra.</p>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card">
                <div class="image-div" style="background-image: url('../../../assets/img/togas/bafd09cf-feed-4e3e-959f-70a73f195860.jpeg');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card">Siempre actualizados</h5>
                    <p class="card-text body-card">Somos tu mejor aliado ya que siempre estaremos a tu alcance con tiempos de respuesta inigualables en la zona puedes confiar en que siempre estaremos en constante actualización para mantener tus instalaciones al día.</p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="image-div" style="background-image: url('../../../assets/img/togas/b7ecebc3-fb1d-4cdc-9a36-d0da77d72624.jpeg');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card">Solución a multiples problemas</h5>
                    <p class="card-text body-card"> Estructurales, mecánicos, hidráulicos de imagen y del giro gasolinero para que tu solo te preocupes en generar ingresos.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="philosophy-content">
    <div class="container">
        <div style="margin-top: 20px;">
            <h2 class="aboutus">Filosofia</h2>
            <p class="sub-title philosophy-margin"> Somos una empresa multiservicios dedicada y especializada para trabajar en todas las necesidades que a las estaciones de servicio con expendio de petrolíferos aquejen. </p>
            <h3 class="title-sub-philosophy">Más de 100 obras exitosas</h3>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4 text-center">
                <div class="thumbnail">
                    <div class="contenedor-img contenedor-imagen">
                        <div class="content-filosofia" style="background-image: url('../../../assets/img/togas/IMG-20210903-WA0237.jpg');"></div>
                        <div class="capa">
                            <h2>Misión</h2>
                            <p>Estamos comprometidos con toda nuestra red de cliente y futuros clientes, satisfacer y resolver con nuestros multiservicios todos los problemas que sus necesidades demanden siempre superando las expectativas de calidad y servicio
                                al igual estamos comprometidos con todos nuestros colaboradores para apoyarlos en su crecimiento personal</p>
                        </div>
                        <div class="caption bg-blue sub_tituloI">
                            <h3 class="value-sub">Misión<br></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 text-center">
                <div class="thumbnail">
                    <div class="contenedor-img contenedor-imagen">
                        <div class="content-filosofia" style="background-image: url('../../../assets/img/togas/IMG-20210903-WA0235.jpg');"></div>
                        <div class="capa">
                            <h2>Visión</h2>
                            <p>Llevar nuestra empresa para ser una de las lideres comerciales y con los servicios mas completos del giro gasolinero siempre manteniendo la calidad, innovación, compromiso y el mejor precio posible para apoyar a nuestros clientes,
                                proveedores y colaboradores. Al igual estamos comprometidos con el crecimiento personal, Laboral y social de todos los que conformamos Togas.</p>
                        </div>
                        <div class="caption bg-blue sub_tituloI">
                            <h3 class="value-sub">Visión<br></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 text-center">
                <div class="thumbnail">
                    <div class="contenedor-img contenedor-imagen">
                        <div class="content-filosofia" style="background-image: url('../../../assets/img/togas/IMG-20210903-WA0128.jpg');"></div>
                        <div class="capa">
                            <h2>Valores</h2>
                            <p>Responsabilidad, empatía, compromiso, lealtad y honestidad</p>
                        </div>
                        <div class="caption bg-blue sub_tituloI">
                            <h3 class="value-sub">Valores<br></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>