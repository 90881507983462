<div class="slider">
    <div class="container container-b">
        <div class="row" style="width: 100%;">
            <div class="col-md-12 col-xs-12">
                <p class="description title-principal text-center"> Rapidez y Calidad <strong class="strong-sub"> al mejor precio</strong></p>
            </div>
        </div>
    </div>
    <div></div>
    <div class="shape"><img src="../../../assets/img/shape-header.svg" alt="shape"></div>
</div>

<div style="margin-bottom: 70px;margin-top: 30px;margin-left: 50px;margin-right: 50px;">
    <h3 class="title-ref">Conoce algunos de nuestros trabajos</h3>
    <h5 class="subtitle-ref">Porque somos especialistas en el cumplimiento de la operación y mantenimiento de tu estación de servicio</h5>
    <hr class="line-blue">
    <div class="row" style="margin-top: 60px;">
        <div class="col-md-4">
            <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery">
            </ngx-gallery>
        </div>
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-12">
                    <h4 class="title-gallery">Acondicionamiento y conexión de transformador seco de 25kva. Provisional. </h4>
                    <ol style="font-size: 18px;margin-top: 10px;
                    font-weight: 400;">
                        <li class="mr-desp">Trayectoria de cableado con cable multiconductor 4x8" sobre techumbre de anden recepcion de pavo.</li>
                        <li class="mr-desp">Preparacion de herramientas y materiales para tendido de cable provisional para conexión de transformador en anden de recepcion de pavo con trayectoria y alimentacion desde subestacion #5.</li>
                        <li class="mr-desp">Llegada de cableado y sujecion por medio de abrazaderas.</li>
                        <li class="mr-desp">Preparacion de transformador para realizar conexiones.</li>
                        <li class="mr-desp">Trayectoria de canalizacion por encima de andenes de recepcion de caja y materia prima seca.</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 60px;">

        <div class="col-md-4">
            <ngx-gallery [options]="galleryOptions" [images]="galleryImages2" class="ngx-gallery">
            </ngx-gallery>
        </div>
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-12">
                    <h4 class="title-gallery">Circuito para alimentacion de fuerza para empacadora.</h4>
                    <ol style="font-size: 18px;margin-top: 10px;
                    font-weight: 400;">
                        <li class="mr-desp">Llegada de cable a tablero eyeliner en sub estacion #5 de cable calibre 350kcmil, instalacion de interruptor eye liner 3 x 300 y conexiones.</li>
                        <li class="mr-desp">Instalacion de transicion canalizacion de tuberia a charola tipo escalera proveniente de trayectoria de sub estacion # 5 hacia sub estación morris.</li>
                        <li class="mr-desp">Trayectoria de cableado y llegada por canalizacion en charola tipo escalera y llegada al tablero eye liner de sub estacion #5.</li>
                        <li class="mr-desp">Instalacion de canalizacion tipo charola con soporteria cableado por la misma trayectoria de 3 hilos de cable de cobre calibre 350 kcmil. Y un hilonpara neutro calibe 2/0.</li>
                        <li class="mr-desp">Llegada de canalizacion y cableado, conexiones en alimentador principal de tablero controlador de pulpo.</li>
                    </ol>
                </div>
            </div>
        </div>

    </div>
</div>