<div class="slider">
    <div class="container container-b">
        <div class="row" style="width: 100%;">
            <div class="col-md-12 col-xs-12">
                <p class="description title-principal text-center"> Somos una <strong class="strong-sub"> empresa 100% Mexicana</strong></p>
            </div>
        </div>
    </div>
    <div></div>
    <div class="shape"><img src="../../../assets/img/basic-triangle-bottom.svg" alt="shape"></div>
</div>

<div class="container" style="margin-bottom: 50px;">
    <h3 class="title-why-services" style="text-align: center !important;">Productos y servicios</h3>
    <h5 class="subtitle-ref">Ferre-servicios Industriales y Mantenimiento</h5>
    <hr class="line-blue">
    <div class="row" style="    margin-top: 50px;">
        <div class="col-md-6">
            <img style="width: 100%;" src="https://i.pinimg.com/originals/8d/e6/dd/8de6dd5fe745634a7a97b2cad1aada2f.png">
        </div>

        <div class="col-md-6">
            <h3 class="title-protec">EQUIPO DE PROTECCIÓN</h3>
            <div class="b-exp">
                <p style="    font-size: 18px;
            font-weight: 400;">Somos sinonimo de seguridad, adquiere los mejores accesorios para ti y tus trabajadores.Manejamos unicamente calidad y buen precio.</p>
                <ol style="font-size: 18px;margin-top: 10px;
            font-weight: 400;">
                    <li>EPP.</li>
                    <li>Señaletica.</li>
                    <li>Conos viales.</li>
                    <li>Trafitambos.</li>
                    <li>Extintores.</li>
                    <li>Calzado y ropa de seguridad.</li>
                    <li>Kits contra derrames.</li>


                </ol>
            </div>
        </div>

        <div class="col-md-6" style="margin-top: 50px;">
            <h3 class="title-protec">SERVICIOS</h3>
            <div class="b-exp">

                <p style="    font-size: 18px;
            font-weight: 400;">Contamos con personal altamente capacitado. Deja el trabajo duro para nosotros.</p>

                <ol style="font-size: 18px;margin-top: 10px;
            font-weight: 400;">
                    <li>Colocación de boyas viales.</li>
                    <li>Guardas y topes de estacionamiento.</li>
                    <li>Señalización vial.</li>
                    <li>Reavilitacion y diseño de jardineras.</li>
                    <li>Reactivación de baños públicos.</li>
                    <li>Diseño e impresion de publicidad.</li>
                    <li>Lonas, viniles y mas....</li>
                </ol>
            </div>
        </div>
        <div class="col-md-6" style="margin-top: 50px;">
            <img style="width: 100%;" src="http://dmetalicos.com/site/wp-content/uploads/2017/03/tope.png">
        </div>

        <div class="col-md-6" style="margin-top: 50px;">
            <img style="width: 100%;" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUEcbXt-JgBb3l6Wy5JLdxI8YLyA2MM58iKg&usqp=CAU">
        </div>

        <div class="col-md-6" style="margin-top: 50px;">
            <h3 class="title-protec">HERRAMIENTAS</h3>
            <div class="b-exp">

                <p style="    font-size: 18px;
            font-weight: 400;">Ten a la mano las mejores herramientas, contamos con las mejores marcas</p>
                <ol style="font-size: 18px;margin-top: 10px;
            font-weight: 400;">
                    <li>Martillo demoledor.</li>
                    <li>Taladros.</li>
                    <li>Pulidoras.</li>
                    <li>Caladoras.</li>
                    <li>Desbrozadoras.</li>
                    <li>Hidrolavadoras.</li>
                </ol>
            </div>
        </div>
    </div>
</div>


<div class="container" style="    margin-top: 60px;
margin-bottom: 80px;">
    <div class="text-center"> <img style="width: 180px;margin-bottom: 5px;" src="../../../assets/img/togas7.png">
    </div>
    <h5 class="subtitle-ref">Servicios a la industria en general</h5>
    <hr class="line-blue">
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
            <div class="card">
                <div class="image-div-us" style="background-image: url('../../../assets/img/togas/IMG-20210903-WA0215.jpg');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card-us">Mantenimiento</h5>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="card">
                <div class="image-div-us" style="background-image: url('../../../assets/img/togas/IMG-20210903-WA0289.jpg');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card-us">Construcciones pluviales y sanitarias</h5>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="card">
                <div class="image-div-us" style="background-image: url('../../../assets/img/togas/IMG-20210903-WA0246.jpg');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card-us">Construcción estructural</h5>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="card">
                <div class="image-div-us" style="background-image: url('../../../assets/img/togas/IMG-20210903-WA0242.jpg');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card-us">Alumbrados perimetrales</h5>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="card">
                <div class="image-div-us" style="background-image: url('../../../assets/img/togas/IMG-20210903-WA0241.jpg');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card-us">Construcciones electricas</h5>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="card">
                <div class="image-div-us" style="background-image: url('../../../assets/img/togas/IMG-20210903-WA0237.jpg');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card-us">Renta de maquinaria pesada</h5>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="card">
                <div class="image-div-us" style="background-image: url('../../../assets/img/togas/IMG-20210903-WA0216.jpg');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card-us">Renta de grua y maniobra HIAB</h5>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="card">
                <div class="image-div-us" style="background-image: url('../../../assets/img/togas/IMG-20210903-WA0128.jpg');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card-us">Herreria y paileria</h5>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>


</div>