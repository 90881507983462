import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class TogasService {

  constructor(private http: HttpClient) {

  }

  postMail(mail) {
    const url = `https://api.solucionestogas.com/contact`;
    return this.http.post(url,mail);
  }

  


}