import { Component, OnInit } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { TogasService } from '../../src/service/togas.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import Swal from 'sweetalert2'

export interface Mail {
  name: any;
  email:any;
  phone:any;
  affair:any;
  message:String;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})



export class ContactComponent implements OnInit {

  mail:Mail = {
    name:'',
    affair:'',
    email:'',
    message:'',
    phone:''
  }

  items: any = [];

 
  ngOnInit(): void {
    
      }
  

  constructor(private servicetoGas: TogasService,private router: Router,private titleService: Title) {
    window.scrollTo(0, 0);

  }
 
  email = new FormControl('', [Validators.required, Validators.email]);
  telefono = new FormControl ('',[Validators.required,Validators.maxLength(10),Validators.minLength(8)]);
  nombre= new FormControl ('',[Validators.required,Validators.maxLength(20),Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]);
  apellido= new FormControl ('',[Validators.required,Validators.maxLength(20),Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]);
  messagge= new FormControl ('',[Validators.required,Validators.maxLength(50),Validators.minLength(10)]);
asunto = new FormControl('', [Validators.required, Validators.minLength (4)]);
  ErrorMessageName(){
    if (this.nombre.hasError('required')) {
      return 'Ingresa un nombre valido';
    }
    return this.nombre.hasError('nombre') ? 'Nombre de usuario no valido' : ''; 

  }
  /******/
  ErrorMessagePhone(){
    if (this.telefono.hasError('required')) {
      return 'Ingresa un numero valido';
    }
    return this.telefono.hasError('telefono') ? 'Ingresa un telefono valido' : '';

  }
  /******/

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Debes ingresar un valor';
    }
    return this.email.hasError('email') ? 'Cuenta no valida' : ''; 
  }

  ErrorMessageMenssage() {
    if (this.messagge.hasError('required')) {
      return 'Ingresa un mensaje';
    }
    return this.messagge.hasError('messagge') ? 'Mensaje no valido' : ''; 
  }
  ErrorMessageAsunto(){
    if (this.asunto.hasError('required')) {
      return 'Ingresa un mensaje';
    }
    return this.asunto.hasError('asunto') ? 'Asunto no valido' : ''; 
  }



  send(){
    console.log('holaaaaaa');
    if(this.email.valid && this.messagge.valid && this.telefono.valid && this.asunto.valid 
      && this.apellido.valid && this.nombre.valid){
    this.mail.email = this.email['value'];
    this.mail.name = this.nombre['value'] + " " + this.apellido['value'];
    this.mail.affair = this.asunto['value'];
    this.mail.phone = this.telefono['value'];
    this.mail.message = this.messagge['value'];

    this.servicetoGas.postMail(this.mail).
      subscribe(items => {
        this.email.setValue('');
        this.nombre.setValue('');
        this.apellido.setValue('');
        this.asunto.setValue('');
        this.telefono.setValue('');
        this.messagge.setValue('');
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Correo enviado correctamente',
          showConfirmButton: false,
          timer: 1500
        })
      })

    }else{
      return;
    }
  }



}
