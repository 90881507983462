import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  customOptions :OwlOptions;
  constructor() { 

    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
      this.customOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        autoplay:true,
        autoplayTimeout:1700,
        navSpeed: 200,
        navText: ['', ''],
        responsive: {
          0: {
            items: 2
          },
          400: {
            items: 2
          },
          740: {
            items: 3
          },
          940: {
            items: 5
          }
        },
        nav: true
      }
     }
    else {
      this.customOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        autoplay:true,
        autoplayTimeout:1700,
        navSpeed: 200,
        navText: ['', ''],
        responsive: {
          0: {
            items: 4
          },
          400: {
            items: 4
          },
          740: {
            items: 3
          },
          940: {
            items: 5
          }
        },
        nav: true
      }
     }
  }

  ngOnInit(): void {
  }
  

}
