<!--<div style="display: flex;
align-items: center;height: 100vh; width: 100%;">
    <div style="    width: 100%;
    text-align: center;">
        <h3 style="font-weight: 700;">Sitio en construcción</h3>
        <img style="width: 300px;" src="../../../assets/img/togas.jpeg">
    </div>
</div>
-->

<div class="image-index magictime vanishIn">
    <div class="above-fold">
        <div class="hero-section">
            <img src="https://media-exp1.licdn.com/dms/image/C561BAQHV4pHcGXokLA/company-background_10000/0/1614982819871?e=2159024400&v=beta&t=y8h9fVvK_gh3IuQtiN9YMip48EFhkXqRpka9SwhtzuQ" /></div>
        <div class="hero-section">
            <img src="https://media-exp1.licdn.com/dms/image/C561BAQHV4pHcGXokLA/company-background_10000/0/1614982819871?e=2159024400&v=beta&t=y8h9fVvK_gh3IuQtiN9YMip48EFhkXqRpka9SwhtzuQ" /></div>
        <div class="hero-section">
            <img src="https://media-exp1.licdn.com/dms/image/C561BAQHV4pHcGXokLA/company-background_10000/0/1614982819871?e=2159024400&v=beta&t=y8h9fVvK_gh3IuQtiN9YMip48EFhkXqRpka9SwhtzuQ" /></div>
    </div>
    <div class="intro" id="sectionhome">
        <div class="row" style="margin-left: 0;margin-right: 0;">
            <div class="col-md-12 wedding-align">
                <div class="wdt-info">
                    <img class="img-principal" src="../../../assets/img/togas6.png">
                    <div class="row">
                        <div class="col-md-12">
                            <div>
                                <owl-carousel-o [options]="customOptions ">
                                    <ng-template carouselSlide>
                                        <img class="img-carousel " src="../../../assets/img/marcas/fepetro3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/cim-tek3.png "></ng-template>
                                    <ng-template carouselSlide><img style="margin-top: 14px; " class="img-carousel " src="../../../assets/img/marcas/opw3.png "></ng-template>
                                    <ng-template carouselSlide><img style="margin-top: 14px; " class="img-carousel " src="../../../assets/img/marcas/gilbarco3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/wayne3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/conitech3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/adt3.png"></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/schweerd3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/emco3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/husky3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/fiber3.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/civacon3.png "></ng-template>
                                    <ng-template carouselSlide><img style="margin-top: 13px; " class="img-carousel " src="../../../assets/img/marcas/petroclear.png "></ng-template>
                                    <ng-template carouselSlide><img class="img-carousel " src="../../../assets/img/marcas/liquid3.png"></ng-template>
                                </owl-carousel-o>
                            </div>
                        </div>

                    </div>
                    <!--  <p style="font-family: serif;" class="btn-info-index animate__animated animate__bounce">Somos especialistas en instalación y Programación de consolas de monitoreo dispensarios de combustible y tanques de almacenamiento. </p>

                     <h1 style="font-family: republica;" class="txt anim-text-flow animate__bounceInLeft title-ind effect">Somos</h1>
                    <h1 style="font-family: republica;" class=" animate__bounceInLeft title-ind effect">especialistas en:</h1>

                    -->
                </div>
            </div>
            <div class="col-md-6 text-center">

            </div>
        </div>
    </div>

    <div>

    </div>

</div>

<div class="container" style="margin-bottom: 70px;margin-top: 90px;">
    <div>
        <div class="row">
            <div class="col-md-6">
                <h3 class="title-why">¿Porque nosotros?</h3>
            </div>
            <div class="col-md-6 dir-btn">
                <button (click)="openabout()" class="btn-togas"><span class="mat-button-wrapper">Conocer Más</span><div class="mat-ripple mat-button-ripple"></div><div class="mat-button-focus-overlay"></div></button>

            </div>
        </div>
    </div>

    <hr class="line-blue">
    <h5 class="subtitle-why">Somos especialistas en el cumplimiento de la operación y mantenimiento de tu estación de servicio, con más de 100 obras exitosas y 10 años de experiencia, brindándoles a nuestros clientes los mejores servicios además que contamos con personal altamente
        capacitado para lograr un mejor trabajo.</h5>
    <div class="row">
        <div class="col-md-4">
            <div class="card">
                <div class="image-div" style="background-image: url('../../../assets/img/togas/cuidamos.png');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card">Cuidamos de ti</h5>
                    <p class="card-text body-card">En TOGAS garantizamos calidad superior en todos nuestros trabajos y servicios por mas sencillos o complejos que estos puedan ser, cuidamos de tu empresa y economía como si fuera nuestra.</p>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card">
                <div class="image-div" style="background-image: url('../../../assets/img/togas/bafd09cf-feed-4e3e-959f-70a73f195860.jpeg');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card">Siempre actualizados</h5>
                    <p class="card-text body-card">Somos tu mejor aliado ya que siempre estaremos a tu alcance con tiempos de respuesta inigualables en la zona puedes confiar en que siempre estaremos en constante actualización para mantener tus instalaciones al día.</p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="image-div" style="background-image: url('../../../assets/img/togas/b7ecebc3-fb1d-4cdc-9a36-d0da77d72624.jpeg');"></div>
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card">Solución a multiples problemas</h5>
                    <p class="card-text body-card"> Estructurales, mecánicos, hidráulicos de imagen y del giro gasolinero para que tu solo te preocupes en generar ingresos.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="togas-responsa1">
    <div class="container">
        <h1 class="title-sector">MANTENIMIENTO AL SECTOR GASOLINERO</h1>
        <hr class="line-blue2">
        <h5 class="subtitle-gas">Somos especialistas en el cumplimiento de la operación y mantenimiento de tu estación de servicio, con más de 100 obras exitosas y 10 años de experiencia, brindándoles a nuestros clientes los mejores servicios además que contamos con personal
            altamente capacitado para lograr un mejor trabajo.</h5>
        <div class="row ">
            <div class="col-md-4">
                <figure class="snip1477">
                    <img style="height: 300px;" src="../../../assets/img/togas/IMG-20210903-WA0235.jpg " alt="sample38 " />
                    <div class="title ">
                        <div>
                            <h2>Mantenimiento</h2>
                            <h4 style="font-size: 18px; ">PREVENTIVO Y CORRECTIVO</h4>
                        </div>
                    </div>
                    <figcaption>
                        <p>
                            Todo Nuestro personal está certificado y capacitado para asegurar el apartado de operación y mantenimiento de la nom/005/ASEA/2017 </p>
                    </figcaption>
                </figure>
            </div>
            <div class="col-md-4 ">
                <figure class="snip1477 "><img style="height: 300px;" src="../../../assets/img/togas/IMG-20210903-WA0216.jpg " alt="sample91 " />
                    <div class="title ">
                        <div>
                            <h2>Construcción</h2>
                            <h4 style="font-size: 18px; ">y más...</h4>
                        </div>
                    </div>
                    <figcaption>
                        <p> “Obra mecánica Eléctrica y civil”</p>
                    </figcaption>
                </figure>
            </div>

            <div class="col-md-4 ">
                <figure class="snip1477 "><img style="height: 300px;" src="../../../assets/img/togas/IMG-20210903-WA0122.jpg " alt="sample35 " />
                    <div class="title ">
                        <div>
                            <h2>Proyectos</h2>
                            <h4 style="font-size: 18px; "> Alianzas y buenas amistades
                            </h4>
                        </div>
                    </div>
                    <figcaption>
                        <p>Somos una empresa joven directamente en el giro gasolinero, sin embargo, contamos con mas años de experiencia en el giro industrial, constructivo, operativo, residencial y comercial.
                        </p>
                    </figcaption>
                </figure>
            </div>
        </div>


    </div>
</div>
<div class="togas-responsa2">
    <div class="container" style="margin-bottom:110px;margin-top:100px">


        <div class="row" style="margin-top: 60px;">
            <div class="col-md-6" style="text-align: center;">
                <img style="width: 320px;" src="../../../assets/img/togas7.png">
                <h5 class="subtitle-gas">En togas Somos especialistas en instalación y Programación de consolas de monitoreo dispensarios de combustible y tanques de almacenamiento.</h5>
                <div class="wdt-100 text-center">
                    <button (click)="openservices()" class="btn-togas-services"><span class="mat-button-wrapper">Conocer más de nuestros servicios</span><div class="mat-ripple mat-button-ripple"></div><div class="mat-button-focus-overlay"></div></button>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img-galery" id="gallery">
                    <img class="wdt-100 hgt-gallery" src="../../../assets/img/togas/5fcf7dab-9a8c-420a-bf8b-b47327f3afd9.jpeg" />
                    <img class="wdt-100 hgt-gallery" src="../../../assets/img/togas/69091cc5-49d0-428d-b845-9d2ba3c47aeb.jpeg" />
                    <img class="wdt-100 hgt-gallery" src="../../../assets/img/togas/ba451634-e200-4702-a747-fcc50922b1a8.jpeg" />
                    <img class="wdt-100 hgt-gallery" src="../../../assets/img/togas/bafd09cf-feed-4e3e-959f-70a73f195860.jpeg" />
                    <img class="wdt-100 hgt-gallery" src="../../../assets/img/togas/0b2651b7-e477-496b-9451-3772623a0a90.jpeg" />
                </div>
            </div>
        </div>
    </div>

</div>


<div class="container" style="margin-bottom: 50px;margin-top: 80px;">
    <h3 class="title-ref">Refacciones</h3>
    <h5 class="subtitle-ref">Trabajamos con las mejores marcas</h5>
    <hr class="line-blue">
    <div class="row">
        <div class="col-md-4">
            <div class="card">
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card">EQUIPOS DE DESPACHO</h5>
                    <p class="card-text body-card-ref">Dispensarios, pistolas, accesorios, Equipo para asistencia,etc.</p>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card">
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card">SISTEMAS DE CONTROL</h5>
                    <p class="card-text body-card-ref">Equipos de medicion de combustible, monitoreo, sistemas de punto de venta...</p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-body" style="padding: 0;">
                    <h5 class="card-title title-card">ILUMINACION</h5>
                    <p class="card-text body-card-ref">Altas tecnologias LED y anti-explosion con veneficios en ahorro de energia y reduccion de costos.</p>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="container" style="margin-top: 80px;margin-bottom: 80px;">
    <div class="row">
        <div class="col-md-12 shape-contact">
            <p class="title-sector">En TOGAS tenemos la responsabilidad de solucionar los problemas y necesidades de tu empresa, poniendo a tu alcance la facilidad de:</p>

            <ol style="font-size: 18px;
            font-weight: 400;">
                <li>Calibraciones de tanques.</li>
                <li>Calibraciones periódicas y extraordinarias.</li>
                <li>Limpiezas ecológicas.</li>
                <li>Recolección de residuos peligrosos.</li>
                <li>Limpieza de tanques hombre a dentro e inyección de agua.</li>
                <li>Actualizaciones de planos.</li>
                <li>Venta e instalación de paneles solares.</li>
                <li>Personal de limpieza.</li>
                <li>Accesos automáticos con monedero</li>

            </ol>
            <p style="    margin-left: 23px;
            margin-top: 30px;
            font-size: 22px;
            text-align: center;">“Haciendo mas grande nuestra cadena comercial”
            </p><br _ngcontent-vfe-c53="">
        </div>

    </div>
</div>