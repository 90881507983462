import { Routes, RouterModule } from '@angular/router';
import { ServicesComponent } from './components/services/services.component';
import { ContactComponent } from './src/contact/contact.component';
import { GalleryComponent } from './src/gallery/gallery.component';
import { IndexComponent } from './src/index/index.component';
import { UsComponent } from './src/us/us.component';


const APP_ROUTES: Routes = [
    { path: 'inicio', component: IndexComponent },
    { path: 'acercade', component: UsComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'galeria', component: GalleryComponent },
    { path: 'contacto', component: ContactComponent },

    { path: '**', pathMatch: 'full', redirectTo: 'inicio' }
];

export const APP_ROUTING = RouterModule.forRoot (APP_ROUTES);
