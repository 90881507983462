import { Component, OnInit } from '@angular/core';
import {NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {NgxGalleryImage} from '@kolkov/ngx-gallery';
import {NgxGalleryAnimation} from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  galleryImages2: NgxGalleryImage[];

  
  constructor() { 
    window.scrollTo(0, 0);

  }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '100%',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
    },
    // max-width 800
    {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
    },
    // max-width 400
    {
        breakpoint: 400,
        preview: false
    }
  ];
  this.galleryImages = [
    {
        small: '../../../assets/img/togas/w1.png',
        medium: '../../../assets/img/togas/w1.png',
        big: '../../../assets/img/togas/w1.png'
    },
    {
      small: '../../../assets/img/togas/w2.png',
      medium: '../../../assets/img/togas/w2.png',
      big: '../../../assets/img/togas/w2.png'
    },  
    {
      small: '../../../assets/img/togas/w3.png',
      medium: '../../../assets/img/togas/w3.png',
      big: '../../../assets/img/togas/w3.png'
    },
    {
      small: '../../../assets/img/togas/w4.png',
      medium: '../../../assets/img/togas/w4.png',
      big: '../../../assets/img/togas/w4.png'
    },  
    {
      small: '../../../assets/img/togas/w5.png',
      medium: '../../../assets/img/togas/w5.png',
      big: '../../../assets/img/togas/w5.png'
    }
];
this.galleryImages2 = [
  {
      small: '../../../assets/img/togas/q1.png',
      medium: '../../../assets/img/togas/q1.png',
      big: '../../../assets/img/togas/q1.png'
  },
  {
    small: '../../../assets/img/togas/q2.png',
    medium: '../../../assets/img/togas/q2.png',
    big: '../../../assets/img/togas/q2.png'
  },  
  {
    small: '../../../assets/img/togas/q3.png',
    medium: '../../../assets/img/togas/q3.png',
    big: '../../../assets/img/togas/q3.png'
  },
  {
    small: '../../../assets/img/togas/q4.png',
    medium: '../../../assets/img/togas/q4.png',
    big: '../../../assets/img/togas/q4.png'
  },  
  {
    small: '../../../assets/img/togas/q5.png',
    medium: '../../../assets/img/togas/q5.png',
    big: '../../../assets/img/togas/q5.png'
  },
  {
    small: '../../../assets/img/togas/q6.png',
    medium: '../../../assets/img/togas/q6.png',
    big: '../../../assets/img/togas/q6.png'
  },{
    small: '../../../assets/img/togas/q7.png',
    medium: '../../../assets/img/togas/q7.png',
    big: '../../../assets/img/togas/q7.png'
  },{
    small: '../../../assets/img/togas/q8.png',
    medium: '../../../assets/img/togas/q8.png',
    big: '../../../assets/img/togas/q8.png'
  }
];
  }

}
