import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


declare var $: any;

@Component({
  selector: 'app-navigationbar',
  templateUrl: './navigationbar.component.html',
  styleUrls: ['./navigationbar.component.scss']
})
export class NavigationbarComponent implements OnInit {

  variable:boolean = false;

  config ={
    data:JSON.parse(localStorage.getItem('currentUser'))
    }


  constructor(public router: Router,private changeDetector: ChangeDetectorRef,private location: Location) { }
  ngOnInit() {

   
    
  }
  


  toggle = false; 

  isHomeRouteActivated(): boolean{
      var page = this.location.path().indexOf('') > -1
      var page2 = this.location.path().indexOf('contacto') > -1
      if(page2)return false;
      var scrollTop = $(window).scrollTop();
      if (page){
         if(scrollTop > 100){
           if(this.toggle == false){
             this.toggle = false;
          $('#target').toggle("linear");
           }
           return false  
         }else{
           this.toggle = true;
           return true;
         }
      }
      return page;
  }

 
 changestatus(){
 this.variable = true;
 this.changeDetector.markForCheck()
 }



}
